'use client';

import { GtwoOrange } from '@/components/_rebrand/logos';
import { FC } from 'react';

export interface ProductAwardsProps {
  hideComponent?: boolean;
  title: string;
  subtitle: string;
  description: string;
  image: React.ReactNode;

  reviewImage: React.ReactNode;
  reviewCta: {
    title: string;
    href: string;
  };

  awardBadges: React.ReactNode[];
}

const ProductAwards: FC<ProductAwardsProps> = ({ hideComponent, title, subtitle, description, image, awardBadges = [] }) => {
  return (
    <div className={`${hideComponent ? "hidden" : "flex"} bg-white px-3 py-6 md:px-container-desktop md:py-12`}>
      <div className="max-w-grid mx-auto">

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop gap-y-3">
          <div className="lg:col-span-5 flex flex-col gap-3 md:gap-6 relative">
            <div className='flex flex-col gap-3'>
              <p className='text-14/20 uppercase tracking-wide text-gray-700'>{subtitle}</p>
              <p className='text-30/36 md:text-36/42 font-500 tracking-tight text-blue-900'>{title}</p>
              <p className='text-17/24 md:text-20/28 text-blue-1000 lg:pr-8'>{description}</p>
            </div>

            <div className='flex flex-col gap-3 items-start'>
              <div className="flex relative bg-citron-500 items-center rounded-40">
                <div className="text-12/16 p-1">{GtwoOrange()}</div> <div className="text-16/18 pr-2 blue-1000"><span className="font-500">4.7 stars</span> 2,000+ Reviews</div>
              </div>
              <div className="flex flex-wrap items-center justify-between gap-2 xl:pr-8 border-2 border-gray-700 relative bottom-0 xl:absolute w-[440px] left-[-46px] sm:left-0 sm:w-full">
                {awardBadges.map((awardImage, index) => (
                  <div key={index} className="w-[15%] flex flex-col justify-center items-center">
                    {awardImage}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:col-span-7">
            <div className='overflow-hidden rounded-10'>
              {image}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProductAwards;
